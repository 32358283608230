.containter-documents-public{
    width: 100%;
}

.public-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.public-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.public-01{
    display: flex;
    flex-direction: row;
}

.public-item-text{
    font-size: 1em;
    font-family: "Montserrat", sans-serif;
    color: #2d2d2dcf;
    margin-left: 1em;
}


.public-item-text-01{
    font-size: 0.8em;
    font-family: "Montserrat", sans-serif;
    color: #686868;
}