.form-container {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    overflow: hidden;
}


.form-row-product{
  margin-bottom: 15px;
}

.form-row-product.item-01{
  display: grid;
  grid-template-columns: 1.1fr 0.9fr 2fr 1fr;
  gap: 10px;
  font-size: 12px!important;
}

.form-row-product.item-02{
  display: grid;
  grid-template-columns: 1.5fr 1.1fr 1fr 1fr;
  gap: 10px;
  font-size: 12px!important;
}

.form-row-product.item-03 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  font-size: 12px !important;
}


.form-row-product.item-04{
  display: grid;
  grid-template-columns: 2fr 0.3fr 0.5fr 0.6fr 0.7fr;
  gap: 10px;
  font-size: 12px!important;
}


.form-row-product.item-05{
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1.5fr;
  gap: 10px;
  font-size: 12px!important;
}

.form-row-product.item-06{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  font-size: 12px!important;
  margin-bottom: 0px;
}

.form-row-product.item-07 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  font-size: 12px !important;
  margin-bottom: 0px;
}

.input-product-descont{
 display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
  
.form-pushare-pd{
    width: 60%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
}


.form-pushare-pd .rs-input {
  font-size: 12px !important;
  padding: 8px !important;
}

.list-pushare .rs-input{
  font-weight: bold!important;
}

.rs-input{
  font-size: 12px!important;
}

/* .form-pushare-pd div{
  
  width: 100%!important;
  background-color: red;
} */

.custom-tag .rs-picker-clean.rs-btn-close {
  font-size: 10px!important;
}
  


/* .rs-inline-edit > .rs-input {
    font-size: 14px!important;
    width: 80px!important;
    text-align: center!important;
    position: relative!important;
} */


  .list-pushare {
    width: 40%;
    height: 100%;
    background-color: #ece8e8;
    padding: 20px;
    overflow-y: auto;

  }
  
  
  .input-product, .input-cc {
    width: 100%;
    padding: 5px;
    font-size: 13px;
    color: #545454;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-product:focus, .input-cc:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .input-product-list, .input-cc-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    font-size: 11px!important;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    z-index: 99999;
    width: 50%;
  }




  .input-product-item{
    font-size: 11px!important;
  }
  
  .input-product-item, .input-cc-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .input-product-item:hover, .input-cc-item:hover {
    background-color: #f5f5f5;
  }
  
  .input-product-item strong, .input-cc-item strong {
    color: #007bff;
    font-weight: bold;
  }
  
.loading-message {
    padding: 10px;
    color: #999;
    text-align: center;
    position: absolute;
    background-color: #fff;
    z-index: 999;
    width: 100%;
}
  
.submit-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 20%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
  
.form-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}  



.list-container-puchare {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.test{
    background-color: #bbbaba;
}

.list-item-puchare-title{
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    align-items: center; 
    position: sticky;
    top: -20px;
    z-index: 9999;
}

.rs-picker-clean.rs-btn-close{
  font-size: 8px!important;
}


.list-item-puchare {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr;
    background-color: #f7f5f5;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    align-items: center; 
}
  
.list-item-puchare span {
    display: block;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.list-item-puchare:last-child {
    margin-bottom: 0;
}

.form-pushare-top{
    margin-bottom: 5px;
    min-height: 40px;
    width: 100%;
    padding: 5px;
    border: 1px solid #d1cdcd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;


}

.form-pushare-bottom{
    /* min-height: 400px; */
    width: 100%;
    padding: 5px;
    border: 1px solid #e7e4e4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.rs-picker-toggle-value{
    font-size: 12px!important;
}


.list-item-puchare-title.pd{
  grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr 1fr;
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 35px;
  align-items: center;
  
}

.list-item-puchare-title.pd > span{
  height: 100%;
  display: flex;
  align-items: center;
  margin: 5px;
}

.list-item-puchare.pd{
  grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr 1fr;
}


.list-pushare-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  border-radius: 5px;
}


.pushare-top-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
.pushare-top-item > span{
  margin-left: 5px;
  font-size: 11px;
  
}
/* font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;  */
/* FONTE PARA OS ITEMS */


/* font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal; */
/* FONTE PARA OS TITULOS */






@media (max-width: 768px) {

  .form-pushare.pd {
    width: 100%;
  }
  .list-pushare {
    display: none;
  }
  }



