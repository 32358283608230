.form-container {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .form-pushare {
    width: 60%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .custom-tag .rs-picker-clean.rs-btn-close {
    font-size: 10px!important;
  }
  
  .list-pushare {
    width: 40%;
    height: 100%;
    background-color: #ece8e8;
    padding: 20px;
    overflow-y: auto;

  }
  
  .input-product-container, .input-cc-container {
    position: relative;
    width: 100%;
  }
  
  .input-product, .input-cc {
    width: 100%;
    padding: 5px;
    font-size: 13px;
    color: #545454;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-product:focus, .input-cc:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .input-product-list, .input-cc-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    font-size: 11px!important;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    z-index: 99999;
    width: 50%;
  }




  .input-product-item{
    font-size: 11px!important;
  }
  
  .input-product-item, .input-cc-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .input-product-item:hover, .input-cc-item:hover {
    background-color: #f5f5f5;
  }
  
  .input-product-item strong, .input-cc-item strong {
    color: #007bff;
    font-weight: bold;
  }
  
.loading-message {
    padding: 10px;
    color: #999;
    text-align: center;
    position: absolute;
    background-color: #fff;
    z-index: 999;
    width: 100%;
}
  
.submit-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 20%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
  
.form-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
}  



.list-container-puchare {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}


.rs-anim-fade.rs-anim-in.rs-picker-check-menu.rs-picker-popup.placement-bottom-start{
    z-index: 9999!important;
}

.test{
  background-color: #cecece;
}


.pushare-sc-bottom{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.list-item-puchare-title-sc{
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 0.5fr 1fr 1fr 0.9fr 0.8fr;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    align-items: center; 
    position: sticky;
    top: -20px;
    z-index: 9999;
}


.menu-custom-picker{
  width: 100%;
  display: grid;
  grid-template-columns: 0.1fr 0.4fr 0.3fr 0.2fr;
  gap: 9px;
}

.menu-custom-picker-titles {
  display: grid;
  width: 100%;
  grid-template-columns: 0.1fr 0.4fr 0.3fr 0.2fr;
  gap: 9px;
  margin-bottom: 5px;
}



.rs-anim-fade.rs-anim-in.rs-custom-picker.rs-picker-select-menu.rs-picker-popup.placement-bottom-start .rs-picker-select-menu-item{
  padding: 8px 0px!important;
}

.item-custom-picker{
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-custom-picker.descri{
  display: flex;
  justify-content: center;
}

.item-custom-data-picker{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.item-custom-picker-title{
  font-size: 11px;
  font-weight: bold;
  color: #8d8b8b;
  display: flex;
  align-items: center;
  justify-content: center;
}



.rs-picker-list-item.custom-users-add:hover{
  background-color: rgb(from #cce9ff r g b / 50%)!important;
  color: #1675e0!important;
}

.custom-user-tag-picker .rs-picker-textbox {
  display: flex;
}
.custom-tag:hover{
  background-color: #dad8d8!important;
}


/* .custom-user-tag-picker:focus-within .rs-picker-textbox {
  display: block;
} */

.rs-icon.custom-users{
  color: #01614b!important;
}


.rs-picker-select-menu-item.custom-item-users-add:hover{
  background: transparent!important;
  color: inherit!important;
}

.rs-custom-add-users{
  padding-bottom: 0px!important;
}

.custom-add-item-users{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 3px;
  color: #000;
  background-color: #e0e2e4;
  border: 1px solid #d4d6d8;
}

.custom-add-item-users:hover{
  background-color: #d4d6d8;
  cursor: pointer;
}

.custom-add-item-users.hidden{
  display: none;
}


.rs-picker-clean.rs-btn-close{
  font-size: 8px!important;
}


.list-item-puchare-sc {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 0.5fr 1fr 1fr 0.9fr 0.8fr;
    background-color: #f7f5f5;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    align-items: center; 
}
  
.list-item-puchare-sc span {
    display: block;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.list-item-puchare-sc:last-child {
    margin-bottom: 0;
}


.form-row-grid{
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    width: 100%;
    margin-bottom: 20px;
}
.btn-add-mobile{
  display: none;
}

@media (max-width: 768px){
    .pushare-sc-bottom{
      display: none;
    }

    .btn-add-mobile{
      display: block;
    }


    

    .form-container {
        flex-direction: column!important;
    }
    .form-pushare {
      width: 100%!important;
    }
    .form-row-grid {
      grid-template-columns: 1fr;
    }
    .custom-input-picker {
      width: 100%!important;
    }
    
}