.navbar-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 4em;
    background-color: #ffffffe3;
}




.navbar-row-01{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;

}

.navbar-avatar{
    width: 40px;
    height: 40px;
    background-color: #00664e!important;
    
}

.custom-inpt-group{
    width: 280px!important;
    border: none!important;
}


.navbar-alert{
    width: 40px;
    height: 40px;
    background-color: #00664e!important;

}

.navbar-avatar:hover{
    cursor: pointer;
}


.notification-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.notification-container-row1{
    font-size: 15px;
    font-weight: bold;
}

.notification-container-row2{
    font-size: 12px;
}

.navbar-menu{
    visibility: hidden;
}


@media (max-width: 768px){
    .navbar-menu{
        visibility: visible;
    }
    .custom-inpt-group{
        width: 100%!important;
        border: 1px solid #ccc!important;
        
    }
    
    
}


