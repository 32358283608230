

.user-info {
    display: grid;
    grid-template-columns: 0.1fr 2fr 0.1fr;
    grid-template-rows: auto;
    width: 100%;
    background-color: #fefdfd;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 15px;
    min-height: 150px;
    margin-bottom: 25px;
    gap: 10px;
    align-items: center;
}


.user-info-child-01{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}




.user-info-child-02 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 28px;
}

.user-info-child-03 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.user-title {
    font-size: 17px;
    font-weight: 600;
    color: #333;
}

.user-subtitle {
    font-size: 11px;
    color: #939292;
}

.edit-button {
    border: 1px solid gray!important;
    padding: 5px 10px!important;
    font-size: 12px!important;
}


.tabs-custom.rs-tabs.rs-tabs-vertical > .rs-nav.rs-nav-subtle.rs-nav-vertical{
    width: 10%!important;
    min-width: 190px!important;
}



.custom-inline{
    padding-left: 5px!important;
    border-bottom: none!important;
    border-top: none!important;
    border-right: none!important;
    border-radius: 0px!important;
    outline: none!important;
    font-size: 13px!important;
    color: #333!important;
    font-weight: bold!important;
}

.custom-inline:hover{
    background-color: #f0efef00!important;
    cursor: pointer!important;
    border-radius: 0px!important;
}



.user-info-02{
    display: grid;
    grid-template-columns: 2fr 0.1fr;
    grid-template-rows: auto;
    width: 100%;
    background-color: #fefdfd;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
    gap: 10px;
    align-items: center;
}

.user-informacoes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto); /* Define 4 linhas ajustadas ao conteúdo */
    gap: 10px;
    padding-top: 20px;
    width: 60%;
    height: 100%;
}

.informacoes-content{
    display: flex;
    flex-direction: column;
}