.leader-board{
    max-height: 300px!important;
    overflow-y: auto;
    scrollbar-width: none; /* Para Firefox */
    -ms-overflow-style: none;
}

.leader-board::-webkit-scrollbar {
    display: none; /* Para Chrome, Safari e Opera */
}


.leader-item:hover{
    background-color: #f1f1f1;
}

@media only screen and (min-width: 1568px) {
    .leader-board{
        max-height: 400px!important;
    }
}