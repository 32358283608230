.container-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #ffffffda;
    padding: 0;
    margin: 0;
}

.login-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 370px;
    height: 500px;
    padding: 10px;
    margin: 0;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #6f6f6f46;
    box-shadow: 0 0 10px 0 #1111112a;
}

.login-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;

}
.login-top > strong{
    font-size: 1.7em;
    color: #141414b2;
    margin-top: 1em;
    font-weight: 600;
    font-style:initial
    

}


.login-inputs{
    width: 100%;
    margin-top: 1em;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.login-recoveri{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -5px;
    margin-top: -5px;
}

.login-body{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    position: relative;

}

.login-buttom{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.test{
    background-color: #00000050;
}

@media (max-width: 768px){
    .login-content{
        width: 350px;
    }
}