.folder-gen {
    width: 100%;
    position: absolute;
    background-color: #d3d3d3;
    height: 0;
    z-index: 999;
    top: 100%;
    transition: height 0.5s ease-out, top 1.5s ease-out;
    bottom: 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.folder-gen.active {
    height: 100vh;
    top: 0;
}

.folder-gen.no-transition {
    transition: none;
}

.rs-timeline-item-dot.rs-timeline-item-custom-dot{
    left: -10px!important;
}



.card-timeline{
    margin-left: 20px;
    padding: 10px;
    position: relative;
}

.timeline-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeline-content{
    margin-top: 10px;
    
}

.document-timeline{
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: -5px;

}

.icon-buttom-activity.active{
    background-color: #747474!important;
    color: white!important;
}

.input-search{
    width: 200px;;
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 25px;   
}

.input-search:focus{
    outline: none;
}


.content-users{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    width: 100%;
    position: relative;
}

.icon-users:hover{
    cursor: pointer;
    color: #747474;
    opacity: 1.2;
}

.content-list-users{
    z-index: 999;
    visibility: hidden;

}
.content-list-users.active{
    visibility: visible;
}