.rs-nav.rs-nav-subtle.rs-nav-horizontal{
    min-height: 2.8em;

}


.rs-drawer-header .rs-drawer-header-close{
    color: #fff!important;
    background-color: #ffffff!important;
}

.rs-modal-body-close.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-sm{
    display: none!important;
}


.version-data{
    background-color: #f3f6fa;
    border-radius: 5px;
    color: #383838;
    border: 1px solid #f3f6fa;
}
.version-data.active{
    border: 1px solid #00664e;
}

.version-data:hover{
    border: 1px solid #00664e;
}

@media (max-width: 768px){
    .drawer-document-property{
        width: 300px!important;
    }
}