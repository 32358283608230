.main-content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100vh;
}



.table-documents.main{
    position: relative;
    height: 100vh;
    

}
.main-img{
    opacity: 0.1;
    height: 60%;
    width: 50%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.main-row-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 12%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    

    
}

.main-btn-01{
    width: 20%;
}


.main-child-01{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.main-child-01 > strong{
    font-size: 1.5em;
    font-family: "Montserrat", sans-serif;
    color: #2d2d2dcf;
    margin-top: 0.5em;


}

.main-child-02{
    display: flex;
    flex-direction: row;
}


.main-child-02 > select{
    width: 100%;
    border: none;
    background-color: #ffffff07;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    color: #474747cd;
    margin-top: 0.5em;
    padding: 0px;
    border-radius: 0px;
    
}


.main-child-02 > select:focus {
    outline: none;
}


.main-row-2{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    background-color: #ffffff;
}

.input-table-01{
    background-color: #53525200;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.img-main{
    height: 100%;
    width: 75%;
}




@media (max-width: 768px) {
    .main-child-01 > strong{
        font-size: 1em;
    }
    .img-main{
        height: 60%;
        width: 100%;
    }
}