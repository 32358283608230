.container-cards-dashboard {
    display: grid;
    grid-template-columns: 1fr 0.4fr 0.4fr 0.4fr;
    grid-template-rows: 1fr;
    width: 100%;
    grid-column-gap: 10px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
    height: 60%;
    border: 1px solid #e8e8e8;
}

.input-table-folder-01.dashboard{
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 0px;
    background-color: #0784f200;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
    height: 170px;
    margin-bottom: 5px;

}   


.cards-dashboard {
    background-color: #ffffff00;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.cards-dashboard-02{
    background-color: rgba(255, 32, 32, 0);
}

.cards-dashboard.first{
    justify-content: flex-start;
    align-items: center;
}


.cards-dashboard-02 > p{
    font-size: 0.8em;
    
}

.data-content{
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    position: relative;
}

.data-options{
    position: absolute;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px;

}




.cards-dashboard-02 > hr{
    padding: 0px!important;
    margin: 0px!important;
    background-color: #00664e;
    border: 2px solid #00664e;
    border-radius: 8px;
    width: 30%;
}

.cards-dashboard-02 > h2{
    font-size: 3em
}


.icon-dash{
    font-size: 3.5em!important;
    background-color: #00664e;
    padding: 11px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
}

.refuse{
    background-color: #6e6e6e;
}

.pending{
    background-color: #01795da4;
}


.cards-dashboard.last{
    margin-right: 0;

}




.dashboard-content {
    display: grid;
    grid-template-columns: 1fr 0.4fr;
    grid-template-rows: 1fr;
    height: 60%;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    margin-bottom: 15px;
}

.test{
    background-color: #abaaaa;
}

.content-dash{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 50%;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.content-dash-01{
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    position: relative;
}

.dashboard-data{
    height: 100%;
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
    flex-direction: column;
}


@media (max-width: 768px){
    .dashboard-content{
        grid-template-columns: 1fr;
    }
    .data-content{
        height: 300px;
    }
    .container-cards-dashboard{
        display: none;
    }
}