.folder-section {
    width: 100%;
    margin-top: 15px;
}

.folder-title {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 10px;
    color: #333;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    position: sticky;
    top: 35px;
    z-index: 50;
}


.input-permission{
    width: 200px;
    padding: 5px;
    height: 25px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
}
.input-permission:focus{
    outline: none;
}


.permission-title-container {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 0.6fr 0.5fr 0.4fr;
    gap: 9px;
    background-color: #ffffff;
    width: 100%;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: top 0.1s ease-in-out;
}

.max-title {
    text-align: left;
}

.permission-item-container {
    display: grid;
    grid-template-columns: 1.4fr 0.5fr 0.5fr 0.5fr 0.6fr 0.5fr 0.4fr;
    gap: 9px;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 0.5px solid #e5e5e5;
}

.permission-item-name {
    text-align: left;
    font-size: 14px;
    padding: 5px;
    color: #333;
}

.permission-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
