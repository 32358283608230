


 .folder_content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100vh;
    padding: 5px;
}


.folder_child_01{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.document_view_container{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    padding: 0px;
}

.column_1{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    border: 1px solid #dad8d8;
    border-radius: 12px;
    padding: 0px!important;
    background-color: #717171;
}

.title-02{
    font-size: 13px;
}



.column_1_top_view{
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 50px;
    padding: 10px!important;
    border-bottom: 1px solid #dad8d8;
}
.column_1_top_01{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}



.column_1_top{
    height: 50px;
    padding: 10px!important;
    border-bottom: 1px solid #dad8d8;
}

.buttom_checkout{
    font-size: 12px!important;
    border: 1px solid #d6d5d5!important;
    background-color: #00664e!important;
    color: #fefefe!important;
}
.column_1_body{
    background-color: #717171;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}



.document_view{
    height: 5%!important;
}

.document-content{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.document-content #proxy-renderer{
    background-color: rgb(255, 255, 255)!important;
    border-radius: 0px!important;
}




.document-content #pdf-controls{
    background-color: #00664e;
}

.document-content #pdf-download{
    display: none!important;
}

.document-content #pdf-page-info{
    display: none!important;
}


.document-expand #pdf-controls{
    background-color: #00664e;
}

.document-expand #pdf-download{
    display: none!important;
}
.document-expand #pdf-page-info{
    display: none!important;
}

.document-expand #pdf-renderer{
    background-color: #e0e0e0;
    scrollbar-width:auto;
    scrollbar-color: #e0e0e0;
    -ms-overflow-style: auto;
}



.document-content #pdf-renderer{
    background-color: #e0e0e0;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-color: transparent;
    
    }

.document_properts{
    background-color: rgb(255, 255, 255);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 20px;
}


.document_properts_colum_2{
    background-color: #f3f6fa;
    height: 75%;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.document_properts_colum_3{
    background-color: #f3f6fa;
    border-radius: 10px;
    height: auto;
}
.rs_custom_control{
    margin-bottom: 15px!important;
    font-size: 11px!important;
    font-weight: bold!important;
}

.document_input{
    font-size: 11px!important;
    color: #777676!important;
    padding: 10px;
}

.document_anexos{
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
    height: 100%;
    overflow-y: auto;
}

.document_anexos::-webkit-scrollbar {
    width: 8px;
}

.document_anexos::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.document_anexos::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px; 
}

.document_anexos::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.document_anexos {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; 
}

.document_anexos strong{
    color: #3a3a3a;
    margin-left: 10px;

}


.document_anexos_item{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
}

.document_history{
    list-style: none;
    width: 100%;
    
}



.document_anexos_item:hover{
    background-color: #f3f6fa;
    cursor: pointer;
}

