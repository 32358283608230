.rs-sidenav-item-icon.rs-icon.custom{
    color: #fff!important;
}

.custom-nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%; 
    width: 100%;


}

@media (max-width: 768px) {
    .rs-nav{
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
    }
    .rs-nav.dash{
        display: none!important;
    }

    .rs-sidenav-item-icon.rs-icon.custom{
        display: none;
    }

    .custom-nav{
        align-items: flex-start;
        width: 100%;
    }
}

/* style={{display: 'flex', justifyContent: 'center', height: '100%', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: 'red'}} */