/* font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;  */
/* FONTE PARA OS ITEMS */


/* font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal; */
/* FONTE PARA OS TITULOS */



.sc-purchase-list-container {
    position: relative!important;
}

.sc-ls-purchase-list-titles {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 1fr 1fr 1.2fr 0.5fr 0.8fr 0.2fr;
    gap: 12px;
    background-color: #ffffff;
    padding: 12px;
    margin-bottom: 8px;
    position: sticky;
    top: 40px;
    z-index: 100;
    border: 1px solid #ddd;
    border-radius: 5px;
}



.item-manager.mobile{
    display: none;
}


.sub-purchase-list-titles{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.5fr 0.7fr;
    gap: 9px;
    background-color: #ffffff;
    padding: 10px;
    z-index: 100;
    margin-bottom: 5px;
}

.purchase-list-titles.sticky-top {
    top: 0 !important;
}


.purchase-list-title-item {
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}

.teste{
    background-color: #f6f6f600;
}


.purchase-list-items {
    list-style: none;
    padding: 0;
    margin: 0;
}
.sub-purchase-list-items{
    list-style: none;
    padding: 0;
    margin: 8px 0;
}




.sc-ls-purchase-list-item-content {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 1fr 1fr 1.2fr 0.5fr 0.8fr 0.2fr;
    gap: 9px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    margin: 8px 0;  
}

.sc-ls-purchase-list-item-content:hover{
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1),
        0 1px 2px rgba(0, 0, 0, 0.08);
}



.item-left{
    text-align: left;
}


.item-data-expand.custom-item{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.sc-sub-purchase-list-item-content-child{
    display: grid;
    grid-template-columns: 0.4fr 0.2fr 0.3fr 0.4fr 0.2fr 0.2fr 0.3fr 0.2fr 0.2fr 0.2fr 0.3fr;
    gap: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 12px;
    margin-bottom: 5px; 
    height: 80px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.08);
    align-items: center;
}




.sc-purchase-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}


.sub-purchase-list-item-content:hover{
    background-color: #f8fafc;
    cursor: pointer;

}
.sc-pushase-item-container{
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
}




.item-expand{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.item-expand.sc{
    display: flex;
    align-items: center;
}

.item-title-expadend{
    font-size: 12px;
    color: #777877;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.item-data-expand{
    font-size: 11px;
    color: #a7a6a6;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}


.purchase-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.purchase-list-item.custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}



.sc-purchase-list-title-item{
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}


.sc-purchase-list-items{
    list-style: none;
    padding: 0;
}



.item-manager{
    font-size: 12px;
    color: gray;
}


.item-text {
    display: flex;
    flex-direction: column;
}

.item-name {
    font-size: 12.5px;
    color: #272626;
    font-family: 'Montserrat';
}

.item-manager.custom-descri {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    text-align: center;
}


.purchase-list-subitems {
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #f5f4f4;
}


.item-title-sm{
    display: none;
}

.pagination-container{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: #ebe9e9;
}
.item-manager.descri-more{
    display: none;
}


.purchase-list-item.descricao.active{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 5px;
}


.progress-bar-data{
    font-size: 10px!important;
    width: 80%!important;
}

.progress-data{
    font-size: 10px;
    position: absolute;
    pointer-events: none;
    color: #fff;
    font-weight: bold;
}
.item-expand.nf{
    margin-left: 36%;
}
.item-expand.qtd{
    margin-left: 20%;
}

.item-manager.progress{
    width: 100%!important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drawer-timeline{
    width: 25%!important;
}


 
@media (max-width: 768px) {


    .item-title-expadend.custom-item{
        display: none;
    }

    .item-data-expand.custom-item{
        display: flex;
        justify-content: flex-start;
        width: 100%!important;
        
    }

    .drawer-timeline{
        width: 90%!important;
    }

    .sc-sub-purchase-list-item-content-child{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: stretch;
    }
    .item-expand{
        display: flex;
        flex-direction: row!important;
        justify-content: flex-start!important;
        align-items: center!important;
        height: 100%;
        width: 100%;
    }

    .item-expand.nf{
        margin-left: 0;
    }
    .item-expand.qtd{
        margin-left: 0;
    }

    .item-data-expand{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }



    .progress-data{
        right: 10%;
    }

    .sc-purchase-list-titles {
        display: none!important;
    }

    .progress-bar-data{
        width: 100%!important;
        padding-right: 0px!important;
        font-size: 5px!important;
    }

    .item-manager.progress{
        width: 100%!important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end!important;
    }

    .list-documents-icon{
        font-size: 20px;
    }
    .item-manager.mobile{
        display: block;
    }

    .item-manager.desktop{
        display: none;
    }

    .item-manager.descri-more{
        display: flex;
        font-size: 12px;
        color: #1404fd;
        align-items: center;
        justify-content: center;
    }

    .purchase-list-titles{
        display: none;
    }

    .item-manager.custom-descri {
        display: none;
    }

    .sc-purchase-list-item-content {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start!important;
    }
    .sc-purchase-list-item{
        display: flex;
        flex-direction: row!important;
        align-items: center;
        justify-content: space-between!important;
        width: 100%;
        padding: 2px;
    }
    

    .item-manager.number{
        color: #333;
        font-size: 14px;
        font-weight: bold;
    }

    .item-title-sm{
        font-size: 12px;
        color: #333;
        margin-bottom: 5px;
    }

    .purchase-list-item.custom{
        display: flex;
        justify-content: space-between;
    }



    .purchase-list-item-content div{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }

    .list-item span {
        font-size: 14px;
        padding: 5px 0;
    }

    /* .item-manager{
        width: 100%;
    } */
    

    .list-item span::before {
        content: attr(data-label);
        font-weight: bold;
        display: block;
        color: #333;
        margin-bottom: 5px;
        
    }
}
