
.rs-icon{
    font-size: 2em;
    color: #606261d5;
}



.rs-nav.rs-dropdown-menu{
    background-color: #000000af!important;
}

.rs-nav.rs-sidenav-item:hover {
    background-color: rgba(0, 0, 0, 0.685)!important;
}

.rs-nav.rs-sidenav-item .active {
    background-color: #000000af!important;
}

.rs-nav.rs-dropdown-item:hover {
    background-color: #000000af!important;
}

.rs-dropdown-toggle.rs-sidenav-item:hover{
    background-color: #000000af!important;
}

.rs-nav.active{
    background-color: #000000af!important;

}



.sidebar-logo{
    height: 40px;
    width: 80%;
}

.sidebar-logo-icon{
    width: 60%;
    height: 20px;
}

.sidebar-title{
    color: #292929c4;
    font-size: 0.8em;
    margin-top: 10px;

}





.rs-nav{
    color: #ffffff!important;
    white-space: nowrap!important;
}



