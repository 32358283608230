.timeline-content{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 10px;

}

.timeline-content-01{
    margin: 10px;
    display: flex;
    flex-direction: column;
    

}

.vertical-timeline-element-content.bounce-in{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 5px;
    margin: 0px;
    background-color: #077844d5!important;
    
}

.vertical-timeline-element-content-arrow{
    border-right: 6px solid #077844d5!important;
    
}


.drawer-timeline.rs-drawer-body{
    padding: 0px!important;
    margin: 0px!important;
}