.container-puchare {
    display: flex;
    flex-direction: row;
    margin-left: 1.2%;
    margin-right: 1.2%;
}

.container-data-main{   
    border-radius: 5px;
    border: none
  
}



.rs-input-data{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    padding: 0 10px;
    font-size: 14px;
    color: #272626;
    font-family: 'Montserrat';
}

.rs-input-data::placeholder{
    color: #272626;
    font-family: 'Montserrat';
}
.rs-input-data:focus{
    outline: none;
}


.pannel-01{
    background-color: blue!important;
}



.container-data-main.top{
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    height: 40px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 12px;
}

.btn-sc{
    background-color: #00664e!important;
    color: #ffffff!important;
}
.btn-sc.mobile{
    display: none!important;
}

.data-cards-content{
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
}


@media (max-width: 768px) {
    .btn-sc{
        display: none!important;
    }
    .btn-sc.mobile{
        display: block!important;
    }
    .data-cards-content{
        height: auto;
    }
}