.container-data-cards{
    width: 100%;
    height: 100%;
    background-color: #f8fafc;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-x: auto;

}


.data-card-item{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-right: 1px solid #e8e8e8;
}

.data-card-item:hover{
    background-color: #e8e8e8;
    cursor: pointer;
}

.data-card-title{
    font-size: 1.2em;
    color: #313030cf;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.data-card-value{
    background-color: rgba(255, 255, 255, 0);
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-family: "Roboto", sans-serif;
    color: #414141;
    font-weight: 400;
    font-style: normal;
}

.data-card-divider{
    padding: 0px!important;
    margin: 0px!important;
    background-color: #00664e;
    border: 2px solid #00664e;
    border-radius: 8px;
    width: 20%;
}

@media (max-width: 768px) {
    .container-data-cards{
        display: none!important;
    }
    
   
}