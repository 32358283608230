/* Sidenav.module.css */
.sidebarContent {
    width: 200px !important;
    background-color: #00664e !important;
    height: 100% !important;
    transition: width 0.6s !important;
    position: relative !important;
}

.sidebarContentActive {
    width: 55px !important;
}

.sidebarLogoContent {
    min-height: 4.5em;
    padding: 5px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rsIcon {
    font-size: 2em;
    color: #606261d5;
}

.rsNavRsDropdownMenu {
    background-color: #000000af !important;
}

.rsNavRsSidenavItem:hover {
    background-color: rgba(0, 0, 0, 0.685) !important;
}

.rsNavRsSidenavItem .active {
    background-color: #000000af !important;
}

.rsNavRsDropdownItem:hover {
    background-color: #000000af !important;
}

.rsDropdownToggleRsSidenavItem:hover {
    background-color: #000000af !important;
}

.rsNavActive {
    background-color: #000000af !important;
}

.sidebarLogo {
    height: 20px;
    width: 70%;
}

.sidebarLogoIcon {
    width: 55%;
    height: 20px;
}

.sidebarTitle {
    color: #292929c4;
    font-size: 0.8em;
    margin-top: 10px;
}

.sidebarButtom {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 5%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.1em;
    background-color: #f7f7f7;
}

.iconRs {
    font-size: 1.2em;
    color: #fff;
    margin-right: 1em;
    position: absolute;
    left: 1em;
}

.rsNav {
    color: #ffffff !important;
    white-space: nowrap !important;
}

.modalLoginContainer {
    background-color: rgba(255, 255, 255, 0.26);
    min-height: 30em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.loginLogo {
    height: 50px;
    width: 50px;
}

@media (max-width: 768px) {
    .sidebarContent {
        display: none !important;
    }
}
