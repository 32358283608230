.form-container {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .form-pushare {
    width: 60%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .list-pushare {
    width: 40%;
    height: 100%;
    background-color: #ece8e8;
    padding: 20px;
    overflow-y: auto;

  }
  
  .input-product-container, .input-cc-container {
    position: relative;
    width: 100%;
  }
  
  .input-product, .input-cc {
    width: 100%;
    padding: 5px;
    font-size: 13px;
    color: #545454;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .input-product:focus, .input-cc:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .input-product-list, .input-cc-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    font-size: 11px!important;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    z-index: 99999;
    width: 50%;
  }




  .input-product-item{
    font-size: 11px!important;
  }
  
  .input-product-item, .input-cc-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .input-product-item:hover, .input-cc-item:hover {
    background-color: #f5f5f5;
  }
  
  .input-product-item strong, .input-cc-item strong {
    color: #007bff;
    font-weight: bold;
  }
  
.loading-message {
    padding: 10px;
    color: #999;
    text-align: center;
    position: absolute;
    background-color: #fff;
    z-index: 999;
    width: 100%;
}


  
.submit-button {
    position: absolute;
    bottom: 10px;
    width: 100%;
    right: 0px;
    height: 20%;
    padding: 20px;
    display: flex;
    align-items: center;
}
  
.form-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}  



.list-container-puchare {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}





.list-item-puchare-title.sa{
  grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 700;
}




.list-item-puchare.sa{
  grid-template-columns: 2fr 0.5fr 1fr 1fr 1fr;
  font-size: 12px!important;
}
  
.list-item-puchare span {
    display: block;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.list-item-puchare:last-child {
    margin-bottom: 0;
}