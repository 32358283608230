

.cards-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    margin-left: 0; 
    padding: 5px;
}

.card {
    flex: 0 0 23.5%; 
    height: 200px; 
    background-color: #ffffff;
    border: 1px solid #bdbcbc; 
    box-shadow: 0 0 1px 0 #bdbcbc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card:hover {
    background-color: #f0f0f0;
    cursor: pointer; 
}



.card-01 span{
    font-size: 0.8em; 
    color: #3a3939d8;
    font-weight: 550;
    font-family: "Montserrat", sans-serif;
}

.card-01{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 90%;
    width: 100%;
}

.text-01{
    font-size: 12px; 
    color: #3a3939d8;
    margin-left: 10px;
}

.card-body{
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    
}
.card-users-icon{
    font-size: 5em;
    opacity: 0.5;
    color: #888888d8;

}


.text-02{
    font-size: 12px; 
    color: #3a3939d8;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    position: absolute;
    bottom: 10px;
}

.teste{
    background-color: #388038;
}

@media (max-width: 768px) {
    .card {
        flex: 0 0 100%; 
    }
}