* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.test{
  background-color: #012b21;
}

.rs-tag-icon-close.rs-btn-close{
  font-size: 5px!important;
}
.rs-uploader-file-item-btn-remove.rs-btn-close{
  font-size: 10px!important;
}

.wcm-all-content.wcm_widget{
	padding: 0px!important;
	margin: 0px!important
}