.form-label{
    font-weight: 600;
    color: #282828b6;
}



.drawer-body{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px!important;
}

.drawer-footer{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
}
.drawer-footer >:nth-child(1){
    width: 80%;
    display: flex;
    justify-content: flex-end;
}

.form-title{
    font-size: 0.9em;
    font-weight: 600;
    color: #282828b6;
}

.form-input{
    width: 100%;
    border: none;
    background-color: #ffffff07;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    color: #474747cd;
    margin-top: 0.5em;
    padding: 0px;
    border-radius: 0px;
}

.helper-text svg.rs-icon{
    font-size: 14px!important;
    margin-top: -10px;
}
.funcao-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}