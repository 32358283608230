/* font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;  */
/* FONTE PARA OS ITEMS */


/* font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal; */
/* FONTE PARA OS TITULOS */



.sc-purchase-list-container {
    margin: 20px 0;
    position: relative;
    min-height: 100vh;
}

.sc-purchase-list-titles.amz {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 1fr 1fr 1.2fr 0.5fr 0.8fr 0.2fr;
    gap: 12px;
    background-color: #ffffff;
    padding: 12px;
    margin-bottom: 8px;
    position: sticky;
    top: 40px;
    z-index: 100;
}

/*  */

.item-manager.mobile{
    display: none;
}


.sub-purchase-list-titles{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.5fr 0.7fr;
    gap: 9px;
    background-color: #ffffff;
    padding: 10px;
    z-index: 100;
    margin-bottom: 5px;
}

.purchase-list-titles.sticky-top {
    top: 0 !important;
}


.purchase-list-title-item {
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}

.teste{
    background-color: #f6f6f600;
}


.purchase-list-items {
    list-style: none;
    padding: 0;
    margin: 0;
}
.sub-purchase-list-items{
    list-style: none;
    padding: 0;
    margin: 8px 0;
}

.pushase-item-container{
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
}


.sc-purchase-list-item-content.amz {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 1fr 1fr 1.2fr 0.5fr 0.8fr 0.2fr;
    gap: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px;
    margin: 8px 0;
    align-items: center;
    
}



.item-left{
    text-align: left;
}

.sa-sub-purchase-list-item-content{
    display: grid;
    grid-template-columns: 0.3fr 0.1fr 0.2fr 0.3fr 0.1fr 0.2fr 0.1fr 0.2fr 0.1fr 0.1fr 0.3fr;
    gap: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 12px;
    margin-bottom: 5px; 
    height: 80px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.08);
    align-items: center;
}
.item-expand.sa{
    display: flex;
    align-items: center;
}




.sc-purchase-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}


.sub-purchase-list-item-content:hover{
    background-color: #f8fafc;
    cursor: pointer;

}






.item-title-expadend{
    font-size: 12px;
    color: #777877;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.item-data-expand{
    font-size: 11px;
    color: #a7a6a6;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}


.purchase-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.purchase-list-item.custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}



.sc-purchase-list-title-item{
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}


.sc-purchase-list-items{
    list-style: none;
    padding: 0;
    margin: 0;
}




.item-text {
    display: flex;
    flex-direction: column;
}

.item-name {
    font-size: 12.5px;
    color: #272626;
    font-family: 'Montserrat';
}

.item-manager.custom-descri {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    text-align: center;
}


.purchase-list-subitems {
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #f5f4f4;
}


.item-title-sm{
    display: none;
}

.pagination-container{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: #ebe9e9;
}
.item-manager.descri-more{
    display: none;
}


.purchase-list-item.descricao.active{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 5px;
}


.progress-bar-data{
    font-size: 10px!important;
    width: 80%!important;
}

.progress-data{
    font-size: 10px;
    position: absolute;
    pointer-events: none;
    color: #fff;
    font-weight: bold;
}




 
@media (max-width: 768px) {
    .progress-data{
        right: 10%;
    }

    .progress-bar-data{
        width: 30%!important;
        padding-right: 0px!important;
        font-size: 5px!important;
    }

    .list-documents-icon{
        font-size: 20px;
    }
    .item-manager.mobile{
        display: block;
    }

    .item-manager.desktop{
        display: none;
    }

    .item-manager.descri-more{
        display: flex;
        font-size: 12px;
        color: #1404fd;
        align-items: center;
        justify-content: center;
    }

    .purchase-list-titles{
        display: none;
    }

    .item-manager.custom-descri {
        display: none;
    }

    .sc-purchase-list-item-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .sc-sub-purchase-list-item-content {
        grid-template-columns: 1fr;
        height: auto;
        gap: 8px;
    }

    .item-title-sm{
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
    }

    .purchase-list-item.custom{
        display: flex;
        justify-content: space-between;
    }



    .purchase-list-item-content div{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }

    .list-item span {
        font-size: 14px;
        padding: 5px 0;
    }

    .list-item span::before {
        content: attr(data-label);
        font-weight: bold;
        display: block;
        color: #333;
        margin-bottom: 5px;
    }
}
