.layout-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

}

/* 
.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.MuiSpeedDial-fab.css-1usvvvc-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab{
    background-color: #075e4a!important;
}
 */



.support-icon{
    position: absolute;
    right: 2%;
    bottom: 2%;
    opacity: 0.5;
    cursor: pointer;
}

.support-icon:hover{
    opacity: 1;
}

.item-text-breadcrumb{
    font-size: 1em;
    font-family: "Montserrat", sans-serif;
    color: #2d2d2dcf;
}

.deactive{
    color: #062bcc;
}
@media (max-width: 768px){
    .breadcrumbs-navigations{
        display: none;
    }
}