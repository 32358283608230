.list-container {
  margin: 20px 0;
}

.list-titles {
  display: grid;
  grid-template-columns: 1.7fr 0.6fr 0.5fr 0.5fr 0.6fr 0.5fr 0.4fr;
  gap: 9px;
  background-color: #ffffff;
  padding: 10px;
  position: sticky;
  top: 80px;
  z-index: 100;
  transition: top 0.1s ease-in-out
}

.list-titles.sticky-top {
  top: 0 !important;
}


.list-title-item {
  font-size: 11px;
  word-spacing: -1px;
  text-align: center;
  color: #a7a8a7;
  font-family: 'Montserrat';
}


.list-title-item.area{
  text-align: left;
  margin-left: 20px;

}


.rs-progress-circle-info span{
  font-size: 12px !important;

}

.list-processos {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item-content {
  display: grid;
  grid-template-columns: 1.7fr 0.7fr 0.5fr 0.5fr 0.6fr 0.5fr 0.4fr;
  gap: 9px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}



.list-item-content.custom{
  display: grid;
  grid-template-columns: 2.8fr 0.4fr 0.4fr 0.3fr;
  gap: 9px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  

}

.rs-progress-info > span {
  font-size: 13px !important;
}

.rs-progress-line-bg, .rs-progress-line-inner{
  height: 20px!important;
  
}

.rs-progress-line-inner{
  background: #858585!important;
}






.link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #088fe2a9;
}

.list-processos-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.custom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.icon-button {
  background: #00664e !important;
  color: #fff;
  margin-right: 10px;
  width: 43px !important;
}

.item-text {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-size: 12.5px;
  color: #272626;
  font-family: 'Montserrat'
}

.item-manager {
  font-size: 12px;
  color: gray;
}

.item-loader {
  position: relative;
  margin-right: 20px;
}

.item-count {
  font-size: 11px;
  color: gray;
}

.progress-circle {
  width: 50px;
  display: inline-block;
}



.list-subprocessos{
  margin: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #f5f4f4;
}

.list-expand{
  list-style: none;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #eae8e8;
}
.list-expand li:hover{
  cursor: pointer;
}


.cards-documentos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
}
  
.card-documento {
    box-sizing: border-box;
    padding: 10px;
    border-left: 2px solid #00664e;
    border-right: 1px solid #e5e3e3;
    border-top: 1px solid #e5e3e3;
    border-bottom: 1px solid #e5e3e3;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 2px 0px #0000001a;
}


.rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret{
  font-size: 14px!important;
}

.card-documento.active {
    border-color: green;
}


.list-processo.rs-list-item.rs-list-item-md{
    font-size: 11px;
    padding: 5px!important;
}

.list-processo:hover{
    cursor: pointer;
}

.card-container-area {
  display: flex;
  margin-top: 5px;
  background-color: rgb(255, 255, 255);
}

.cards-area {
  border: 1px solid #e5e3e3;
  background-color: #ffffff;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  border-left: 3px solid #00664e;
  transition: all 1s ease;
}

.cards-area:hover {
  cursor: pointer;
  border-bottom: 1px solid #00664e;
}





.icon-area{
  font-size: 50px;
  color: #00664e;
}


.card-sub{
  display: flex;
  flex-direction:column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.cards-area :nth-child(1) {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
}

.cards-area span {
  font-size: 12px;
  font-weight: bold;
}

.cards-area span + span {
  margin-left: 5px;
}


.teste{
  background-color: #00000074;
}

.processos-list {
    overflow-y: auto;
    padding: 0px!important;
}
  
  /* Estilo para navegadores Webkit (Chrome, Safari) */
  .processos-list::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
  }
  
  .processos-list::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor do track (fundo) */
    border-radius: 10px; /* Borda arredondada */
  }
  
  .processos-list::-webkit-scrollbar-thumb {
    background: #888; /* Cor do thumb (barra de rolagem) */
    border-radius: 10px; /* Borda arredondada */
  }
  
  .processos-list::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do thumb ao passar o mouse */
  }
  
  /* Estilo para Firefox */
  .processos-list {
    scrollbar-width: thin; /* Barra de rolagem fina */
    scrollbar-color: #888 #f1f1f1; /* Cor do thumb e do track */
  }
  
  /* Estilo para IE e Edge */
  .processos-list {
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Ocultar automaticamente a barra de rolagem */
  }
  






@media (max-width: 600px) {
    .cards-documentos {
      grid-template-columns: 1fr;
    }
}
  