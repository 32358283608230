/* font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;  */
/* FONTE PARA OS ITEMS */


/* font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal; */
/* FONTE PARA OS TITULOS */


.purchase-list-container {
    margin: 20px 0;
    position: relative;
    min-height: 100vh;
}

.purchase-list-titles-pd {
    display: grid;
    grid-template-columns: 0.1fr 0.1fr 0.5fr 0.8fr 0.3fr  0.3fr 0.3fr 0.4fr 0.3fr 0.1fr;
    gap: 9px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 5px;
    position: sticky;
    top: 40px;
    z-index: 100;
}

/*  */

.item-manager.mobile{
    display: none;
}


.sub-purchase-list-titles{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 0.5fr 0.7fr;
    gap: 9px;
    background-color: #ffffff;
    padding: 10px;
    z-index: 100;
    margin-bottom: 5px;
}

.purchase-list-titles.sticky-top {
    top: 0 !important;
}


.purchase-list-title-item {
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}




.purchase-list-items {
    list-style: none;
    padding: 0;
    margin: 0;

}

.purchase-list-item-content-pd:hover{
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.08);
}
.sub-purchase-list-items{
    list-style: none;
    padding: 0;
    margin: 8px 0;
}

.pushase-item-container{
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 5px;
    margin-bottom: 10px;
}


.purchase-list-item-content-pd {
    display: grid;
    grid-template-columns: 0.1fr 0.1fr 0.5fr 0.8fr 0.3fr  0.3fr 0.3fr 0.4fr 0.3fr 0.1fr;
    gap: 9px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    margin: 8px 0;
    height: 60px;
    
}



.pd-sub-purchase-list-item-content-child{
    display: grid;
    grid-template-columns: 0.3fr 0.2fr 0.4fr 0.4fr 0.4fr 0.2fr 0.1fr 0.2fr 0.2fr;
    gap: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 12px;
    margin-bottom: 5px; 
    height: 80px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.08);
    align-items: center;
    cursor: default!important;
}



.item-left{
    text-align: left;
}

.sub-purchase-list-item-content{
    display: grid;
    grid-template-columns: 0.7fr 0.4fr 0.5fr 0.5fr 0.5fr 0.5fr 0.4fr 0.4fr;
    gap: 9px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 5px; 
    height: 80px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.08);
}

/* .rs-drawer-header{
    background-color: #f8fafc!important;
} */


.sub-purchase-list-item-content:hover{
    background-color: #f8fafc;
    cursor: pointer;

}



.item-expand{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}


.item-title-expadend{
    font-size: 12px;
    color: #777877;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.item-data-expand{
    font-size: 11px;
    color: #a7a6a6;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}


.purchase-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.purchase-list-item.custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}





.item-text {
    display: flex;
    flex-direction: column;
}

.item-name {
    font-size: 12.5px;
    color: #272626;
    font-family: 'Montserrat';
}

.item-manager.custom-descri {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    text-align: center;
}


.purchase-list-subitems {
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #f5f4f4;
}


.item-title-sm{
    display: none;
}

.pagination-container{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: #ebe9e9;
}
.item-manager.descri-more{
    display: none;
}


.purchase-list-item.descricao.active{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 5px;
}


.progress-bar-data{
    font-size: 10px!important;
    width: 80%!important;
}

.progress-data{
    font-size: 10px;
    position: absolute;
    pointer-events: none;
    color: #fff;
    font-weight: bold;
}


.folder_content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100vh;
    padding: 5px;
}


.folder_child_01 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.document_view_container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    padding: 0px;
}

.column_1 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    border: 1px solid #dad8d8;
    border-radius: 12px;
    padding: 0px !important;
    background-color: #717171;
}

.title-02 {
    font-size: 13px;
}



.column_1_top_view {
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 50px;
    padding: 10px !important;
    border-bottom: 1px solid #dad8d8;
}

.column_1_top_01 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}



.column_1_top {
    height: 50px;
    padding: 10px !important;
    border-bottom: 1px solid #dad8d8;
}

.buttom_checkout {
    font-size: 12px !important;
    border: 1px solid #d6d5d5 !important;
    background-color: #00664e !important;
    color: #fefefe !important;
}

.column_1_body {
    background-color: #717171;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}



.document_view {
    height: 5% !important;
}

.document-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.document-content #proxy-renderer {
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0px !important;
}




.document-content #pdf-controls {
    background-color: #00664e;
}

.document-content #pdf-download {
    display: none !important;
}

.document-content #pdf-page-info {
    display: none !important;
}


.document-expand #pdf-controls {
    background-color: #00664e;
}

.document-expand #pdf-download {
    display: none !important;
}

.document-expand #pdf-page-info {
    display: none !important;
}

.document-expand #pdf-renderer {
    background-color: #e0e0e0;
    scrollbar-width: auto;
    scrollbar-color: #e0e0e0;
    -ms-overflow-style: auto;
}



.document-content #pdf-renderer {
    background-color: #e0e0e0;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-color: transparent;

}

.document_properts {
    background-color: rgb(255, 255, 255);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 20px;
}


.document_properts_colum_2 {
    background-color: #f3f6fa;
    height: 75%;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.document_properts_colum_3 {
    background-color: #f3f6fa;
    border-radius: 10px;
    height: auto;
}

.rs_custom_control {
    margin-bottom: 15px !important;
    font-size: 11px !important;
    font-weight: bold !important;
}

.document_input {
    font-size: 11px !important;
    color: #777676 !important;
    padding: 10px;
}

.document_anexos {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
    height: 100%;
    overflow-y: auto;
}

.document_anexos::-webkit-scrollbar {
    width: 8px;
}

.document_anexos::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.document_anexos::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.document_anexos::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.document_anexos {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.document_anexos strong {
    color: #3a3a3a;
    margin-left: 10px;

}


.document_anexos_item {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
}

.document_history {
    list-style: none;
    width: 100%;

}

.test{
    background-color: #c5c4c4;
}


.document_anexos_item:hover {
    background-color: #f3f6fa;
    cursor: pointer;
}

 
@media (max-width: 768px) {
    .progress-data{
        right: 10%;
    }

    .progress-bar-data{
        width: 30%!important;
        padding-right: 0px!important;
        font-size: 5px!important;
    }

    .list-documents-icon{
        font-size: 20px;
    }
    .item-manager.mobile{
        display: block;
    }

    .item-manager.desktop{
        display: none;
    }

    .item-manager.descri-more{
        display: flex;
        font-size: 12px;
        color: #1404fd;
        align-items: center;
        justify-content: center;
    }

    .purchase-list-titles{
        display: none;
    }

    .item-manager.custom-descri {
        display: none;
    }

    .purchase-list-item-content {
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        align-items: flex-start;   
    }
    .item-title-sm{
        display: block;
        font-size: 12px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
    }

    .purchase-list-item.custom{
        display: flex;
        justify-content: space-between;
    }



    .purchase-list-item-content div{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }

    .list-item span {
        font-size: 14px;
        padding: 5px 0;
    }

    .list-item span::before {
        content: attr(data-label);
        font-weight: bold;
        display: block;
        color: #333;
        margin-bottom: 5px;
    }
} 
