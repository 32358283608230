.item-data {
    height: 30px;
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    color: #474848;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.custom-step .rs-steps-item-icon-wrapper >.rs-steps-item-icon .rs-icon{
    color: #fff!important;
    margin-top: -2px;
    
}

.custom-step .rs-steps-item-status-finish .rs-steps-item-icon-wrapper{
    border-color: #00664e!important;
    background-color: #00664e!important;
}


.custom-step .rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title:after{
    border-color: #00664e!important;
}


.custom-step .rs-steps-item-title{
    font-family: "Roboto", sans-serif;
    font-style: normal
}

.item-data.active{
    height: 20%;
    /* border: 1px solid #f0f0f0;
    border-radius: 5px; */
    padding: 10px;
    
}


@keyframes expandAnimation {
    from {
        height: 0px;
    }
    to {
        height: 100px;
    }
}

.item-data.expand {
    animation: expandAnimation 0.5s ease-in-out forwards;
}