.folder-content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100vh;
}


.folder-row-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 12%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    
}

.folder-btn-01{
    width: 20%;
}


.folder-child-01{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.folder-child-01 > span{
    font-size: 1.5em;
    font-family: "Montserrat", sans-serif;
    color: #2d2d2dcf;
    margin-top: 0.5em;
    margin-left: 0.5em;


}

.folder-child-02{
    display: flex;
    flex-direction: row;
}


.folder-child-02 > select{
    width: 100%;
    border: none;
    background-color: #ffffff07;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    color: #474747cd;
    margin-top: 0.5em;
    padding: 0px;
    border-radius: 0px;
    
}


.folder-child-02 > select:focus {
    outline: none;
}


.folder-row-2{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    background-color: #ffffff;
}

.input-table-folder-01{
    background-color: #53525200;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.table-documents{
    padding: 1.5%;
    height: 100vh!important;
}

.folder-row-3{
    display: flex;
    flex-direction: row;
}