/* .modal-compartilhar .rs-modal-content {
    width: 100%!important;
} */
.compartilhar-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.compartilhar-content-colum1{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}

.compartilhar-content-colum2{
    width: 98%;
    background-color: #f5f3f3;
    min-height: 100px;
    border: 1px solid gainsboro;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;

}
.card-container{
    margin: 10px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
}

.card-container:hover{
    background-color: rgb(252, 251, 251);
}