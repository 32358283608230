.purchase-list-titles-sc-pendentes  {
    display: grid;
    grid-template-columns: 0.1fr 0.2fr 0.4fr 0.5fr 0.5fr 0.8fr 0.1fr 0.2fr 0.3fr 0.4fr 0.5fr 0.7fr;
    gap: 8px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 2%;
    position: sticky;
    top: 40px;
    z-index: 1;
}




.purchase-list-item-sc-pendentes {
    display: grid;
    grid-template-columns: 0.1fr 0.2fr 0.4fr 0.5fr 0.5fr 0.8fr 0.1fr 0.2fr 0.3fr 0.4fr 0.5fr 0.7fr;
    gap: 5px;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    
}

.purchase-list-item-sc-pendentes.sc-pendentes-active{
    border-left: 1px solid #00664e;
}

.purchase-list-item-sc-pendentes:hover {
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1),
        0 1px 2px rgba(0, 0, 0, 0.08);

}


.pushase-item-container-sc-pendentes{
    background-color: #f8fafc;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 5px;
}

.purchase-list-titles-sc-pendentes.active{
    top: 0px;
}