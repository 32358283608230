.document-content{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.document-content #proxy-renderer{
    background-color: rgb(255, 255, 255)!important;
    border-radius: 0px!important;
}



.document-content #pdf-controls{
    background-color: #077642d5;
}

.document-content #pdf-download{
    display: none!important;
}

.document-content #pdf-page-info{
    display: none!important;
}
#beta-hint,#developer-hint,#limit-hint{
    display: none!important;
}