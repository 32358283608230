.container-cadastro{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    width: 100%;
    padding: 0;
    margin: 0;
}

.cadastro-colum1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 10%;
    
}

.cadastro-colum1-row1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-size: 2.5em;
}

.cadastro-colum2{
    height: 90%;
    position: relative;
    padding: 10px;
}

.cadastro-buttom{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cadastro-input{
    width: 100%;
    margin-top: 1em;
    margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
}