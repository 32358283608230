.shared-title-container {
    display: grid;
    grid-template-columns: 1.7fr 0.6fr 0.5fr 0.5fr 0.6fr 0.4fr;
    gap: 9px;
    background-color: #ffffff;
    width: 100%;
    padding: 10px;
    position: sticky;
    z-index: 100;
    transition: top 0.1s ease-in-out
}

.max-title{
    text-align: left;
}

.shared-item-container {
    display: grid;
    grid-template-columns: 1.5fr 0.6fr 0.5fr 0.5fr 0.6fr 0.4fr;
    gap: 9px;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 0.5px solid #e5e5e5;
}

.shared-item-name {
    text-align: left;
    font-size: 14px;
    padding: 5px;
    color: #333;
}

.shared-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}