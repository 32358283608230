.table-documents.open-service-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cards-container.card-open-service{
    width: 100%;
    align-items: center;
    justify-content: center;
}
.card.open-service{
    height: 50vh;
}

.os-input-content{
    min-height: 5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.table-documents.os-app{
    padding-left: 0px;
    padding-right: 0px;
}

.folder-row-2.os-content{
    padding: 10px;
}