.diretorio-input-colum1.aprovacao{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.shared-list.active{
    cursor: pointer;
}

.shared-item-icon{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
}

.list-document-titles.shared.sticky-top {
    top: 0 !important;
}