.cards-container-gestao-compras {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    margin-left: 0; 
    padding: 5px;
    justify-content: flex-start;
}

.card-gestao-compras {
    flex: 0 0 19.5%;
    max-width: 280px;
    height: 180px; 
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}







.card-gestao-compras:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.card-01-compras {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 90%;
    width: 100%;
}


@media (max-width: 768px) {
    .card-gestao-compras {
        flex: 0 0 100%;
        height: 200px;
        max-width: 100%;
    }
    
    .card-01-compras span {
        font-size: 14px;
    }
}
