.main-invite{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}
.main-invite-row1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 5px;
    border-radius: 10px;

}

.main-invite-title{
    font-size: 20px;
    font-weight: 600;
    color: #616161;
    margin-bottom: 10px;
}
@media (max-width: 768px) {
    .main-invite-row1{
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

@media (max-width: 576px) {
    .main-invite-row1{
        width: 350px;
        padding-left: 10px;
        padding-right: 10px;
    }
    
}