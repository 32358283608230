.containter-table-documents{
    width: 100%;
}

.table-header-documents{
    color: #a3a2a2!important;
    font-family: "Montserrat", sans-serif;
}
.teste{
    background-color: #eec230;
}