.row-list{
    min-height: 200px;
    margin-bottom: 10px;
}
.list-seach-content{
    list-style: none;
    margin-top: 10px;
}
.list-seach-content li{
    background-color: #efeeee;
    padding: 10px;
    border: 1px solid #faf9f9;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}


.list-seach-content li:hover{
    background-color: #f5f5f5;
    cursor: pointer;
}


.list-seach-title{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

.list-seach-item-text{
    font-size: 13px;
    color: #202020;
    display: flex;
    flex-direction: column;

}