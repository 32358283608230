.container-puchare {
    display: flex;
    flex-direction: row;
    margin-left: 1.2%;
    margin-right: 1.2%;
}

.container-data-main{   
    border-radius: 5px;
    border: none
  
}





.pannel-01{
    background-color: blue!important;
}



.container-data-main.top{
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    height: 40px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 12px;
}

.btn-sc{
    background-color: #00664e!important;
    color: #ffffff!important;
}
.btn-sc.mobile{
    display: none!important;
}

.data-cards-content{
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
}

.rs-anim-fade.rs-anim-in.rs-picker-select-menu.rs-picker-popup.placement-bottom-start{
    z-index: 9999!important;
}

.custom-picker .rs-picker-clean.rs-btn-close{
    font-size: 9px!important;
}

.Custom-input-picker{
    width: 100%!important;
    position: relative!important;
    border: none!important;
    border-radius: 5px!important;
    outline: none!important;
}

.Custom-input-picker .rs-picker-toggle-active, .rs-picker.rs-picker-focused{
    box-shadow: none!important;
    border-radius: 5px!important;
}



.custom-input-sc{
    height: 36px!important;
}

.container-filter{
    width: 400px;
}



.filter-header{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.filter-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}


.filter-body-more{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.filter-item{
    margin-bottom: 13px;
}


.filter-footer{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
}



.custom-popover{
    padding: 0px!important;
}


.custom-popover .rs-popover-arrow{
    margin-left: 6px!important;
}


.rs-anim-fade.rs-anim-in.rs-picker-popup-date.rs-picker-popup.placement-bottom-start{
    z-index: 9999!important;
}

.input-select{
    font-size: 10px;
    height: 100%;
    width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* style={{fontSize: 10, height: '100%',  width: 75, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} */

@media (max-width: 768px) {

    .input-select{
        font-size: 8px;
        width: 60px;
    }

    .custom-input-sc{
        height: 34px!important;
    }
    .btn-sc{
        display: none!important;
    }
    .btn-sc.mobile{
        display: block!important;
    }
    .data-cards-content{
        height: auto;
    }
}