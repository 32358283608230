.cards-container.cards-os{
    height: 100%;
    padding: 15px;

}
.card.cards-os{
    height: 6rem;
    background-color: #ededee;
}
.cart-info{
    background-color: #ededee;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-text{
    display: flex;
    flex-direction: column;
}