.input-table-folder-01.user-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 5px;
}

.containter-table-documents{
    width: 100%;
}

.list-documents{
    list-style: none;
}


.list-document-titles {
    display: grid;
    grid-template-columns: 1.7fr 0.5fr 0.5fr 0.6fr 0.6fr;
    gap: 9px;
    background-color: #ffffff;
    padding-left: 2px;
    padding-right: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    top: 75px;
    z-index: 100;
    transition: top 0.1s ease-in-out
}
.list-document-titles.sticky-top {
    top: 0 !important;
}


.list-documentos-content{
    display: grid;
    grid-template-columns: 1.7fr 0.5fr 0.5fr 0.6fr 0.6fr;
    gap: 9px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;

}
  

.list-documents-item{
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
    
}
.list-documentos-content:hover{
    cursor: pointer;
    background-color: rgb(250, 249, 249);   
}







.list-documents-item.custom{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
    width: 100%;
}


  
  
.list-document-title-item {
    font-size: 11px;
    word-spacing: -1px;
    text-align: center;
    color: #a7a8a7;
    font-family: 'Montserrat';
}


.list-document-title-item.codigo{
    text-align: left;
  
}
  



.list-documents-icon{
    font-size: 35px;
    color: #ffffffcf;
    padding: 3px;
    border-radius: 5px;
    background-color: #00664e;
}


.div-user-menu {
    display: flex;
    gap: 10px;
    flex-grow: 1;
}

.div-user-menu > button {
    font-size: 12px;
    padding: 8px;
    background-color: #cecccc;
    min-width: 120px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.div-user-menu > button:hover {
    background-color: #8a8989;
    color: #ffffff;
}

.div-user-menu > button.active {
    background-color: #8a8989;
    color: #ffffff;
}

.aprov-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.folder-row-3 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.folder-row-3 input {
    flex-grow: 1; 
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ffffff;
}

.input-group {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    flex: 1;
}

.input-text {
    padding: 8px;
    border: none;
    flex: 1;
    outline: none;
}

.input-select {
    padding: 8px;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
    outline: none;
}


@media (max-width: 768px) {
    .input-table-folder-01.user-space {
        flex-direction: column;
        align-items: flex-start;
       
    }
    .aprov-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
    }

    .div-user-menu {
        flex-direction: column;
        width: 100%; /* Faz os botões ocuparem 100% da largura */
    }

    .div-user-menu > button {
        width: 100%; /* Faz os botões ocuparem toda a linha em telas menores */
    }
}

@media (max-width: 480px) {
    .div-user-menu > button {
        font-size: 10px;
        padding: 6px;
        min-width: 80px;
    }
    

    .folder-row-3 {
        flex-direction: column; /* Empilha os inputs em telas pequenas */
        align-items: flex-start;
    }
}
