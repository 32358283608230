.diretorio-input-colum1.aprovacao{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.shared-list.active{
    cursor: pointer;
}