.pedido-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.8fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 50px;
    row-gap: 5px;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
}

.pedido-grid > div {
    font-size: 11px;
    /* background-color: aqua; */
}
.test{
    background-color: #c0c0c0;
}