.list-document-titles.shared {
    display: grid;
    grid-template-columns: 1.7fr 0.5fr 0.6fr 0.6fr 0.5fr;
    gap: 9px;
    background-color: #ffffff;
    padding-left: 2px;
    padding-right: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    top: 75px;
    z-index: 100;
    transition: top 0.1s ease-in-out
}
.list-document-titles.sticky-top {
    top: 0 !important;
}

.list-documentos-content.shared{
    display: grid;
    grid-template-columns: 1.7fr 0.5fr 0.6fr 0.6fr 0.5fr;
    gap: 9px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;

}



.list-documents-item.shared{
    display: flex;
    align-items: center;
    justify-content: center;
}


.tb-title-01{
    font-size: 13px!important;
}
.containter-documents-compartilhados{
    padding: 0px;
}

.shared-list{
    padding: 5px;
}

.shared-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shared-list.active{
    background-color: #f2f0f0;
}

.shared-01{
    display: flex;
    flex-direction: row;
    width: 55%;
}

.shared-02{
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: space-between;
}

.shared-item-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #777575;
    margin-top: -5px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.shared-02-row1{
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #777575;
    display: flex;
    flex-direction: row;
    
}

.shared-02-row2{
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: #777575;

}


.icon-button-plus{
    margin-right: 5px;
    color: #2f2e2e;
}

.icon-button-plus.active{
    visibility: visible;
}
.icon-button-plus:hover{
    cursor: pointer;
    color: #c4c4c4;
}
.text-menu{
    font-size: 10px!important;
    font-family: "Montserrat", sans-serif;
}