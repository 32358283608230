.folder-row-3.diretorio-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

.diretorio-input-colum2{
    display: flex;
    flex-direction: row;
    align-items: center;

}


.input-table-folder-01{
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #f7f7f7;
    margin-left: 1.5%;
    margin-right: 1.5%;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
}


.document-list{
    margin-top: 2%;
}



.document-list-01:hover{
    background-color: #f6f5f5;
    cursor: pointer;
}


.list-folder-item{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.list-folder-01{
    display: flex;
    flex-direction: row;
}
.list-folder-item-icon{
    margin-right: 10px;
}

.list-folder-item-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    
}

.list-folder-item-text strong{
    font-size: 12px;
}


.list-folder-item-text-01{
    display: flex;
    font-size: 12px;
    flex-direction: row;

}

.list-hover:hover{
    background-color: #f5f5f5;
    cursor: pointer;
}


.breadcrumb-item{
    cursor: pointer;
}
.breadcrumb-item:hover{
    text-decoration: underline;
    color: #7faaff;
}

.tste{
    background-color: #363636;
}